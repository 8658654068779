import graphqlClient from '../../../services/graphqlClient';
import {isNil, pick} from 'ramda';
import getPartnersQuery from '../graphql/getPartnersQuery';
import createPartnerMutation from '../graphql/createPartnerMutation';
import deletePartnerMutation from '../graphql/deletePartnerMutation';
import updatePartnerMutation from '../graphql/updatePartnerMutation';
import {
  receivedPartners,
  addToPartners,
  resetPartner,
  updatePartners,
  deleteFromPartners
} from './slice';

export const getPartners = queryParams => {
  return dispatch => {
    graphqlClient
      .query({
        query: getPartnersQuery,
        variables: {...queryParams}
      })
      .then(result => {
        const {
          data: {busPartners}
        } = result;
        dispatch(receivedPartners(busPartners));
      });
  };
};

export const savePartner = setErrorMessage => {
  return (dispatch, _getState) => {
    const state = _getState();
    const partner = {...state.partners.partner};

    partner.depots = partner.depots.map(depot =>
      pick(['city', 'street', 'postCode'], depot)
    );

    const isCreating = isNil(partner.id);
    const mutation = isCreating ? createPartnerMutation : updatePartnerMutation;
    const variables = isCreating
      ? pick(['name', 'backendId', 'countryId', 'depots'], partner)
      : pick(['id', 'name', 'backendId', 'countryId', 'depots'], partner);
    const successCallback = isCreating ? addToPartners : updatePartners;

    graphqlClient
      .mutate({mutation, variables, errorPolicy: 'all'})
      .then(result => {
        const {errors} = result;
        if (errors) {
          const errorMessage =
            errors[0]?.extensions?.errorMessage || errors[0].message;
          setErrorMessage(errorMessage);
        } else {
          const dataKey = Object.keys(result.data)[0];
          const updatedData = result.data[dataKey];
          setErrorMessage(null);
          dispatch(successCallback(updatedData));
          dispatch(resetPartner());
        }
      });
  };
};

export const deletePartner = (partner, addToast) => {
  return dispatch => {
    graphqlClient
      .mutate({
        mutation: deletePartnerMutation,
        variables: pick(['id'], partner),
        errorPolicy: 'all'
      })
      .then(result => {
        const {
          data: {deleteBusPartner},
          errors
        } = result;
        if (errors) {
          addToast({
            type: 'danger',
            content: errors[0]?.extensions?.errorMessage || errors[0].message
          });
        } else {
          addToast({
            type: 'success',
            content: `Partner ${partner.name} deleted successfully`
          });
          dispatch(deleteFromPartners(deleteBusPartner));
        }
      });
  };
};
