import React from 'react';
import {Button} from '@flixbus/honeycomb-react';
import {Icon, IconDownload} from '@flixbus/honeycomb-icons-react';
import {isNotNilOrEmpty} from 'ramda-adjunct';
import {acquireAccessToken} from 'Pages/Auth/auth.config';
import {getUnitsSystemFromLocalStorage} from '../../../Common/LocalUnits';

const DownloadAnalyticsViewButton = ({
  name,
  searchFilters,
  endPointName,
  fileName,
  selectedRows
}) => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/${endPointName}?`;

  const getSearchParams = () => {
    const searchParamsJson = {};

    if (isNotNilOrEmpty(selectedRows)) {
      searchParamsJson['productionCostAnalyticsViewEntryIds'] = selectedRows;
    }

    if (isNotNilOrEmpty(searchFilters.partners)) {
      searchParamsJson['busPartners'] = searchFilters.partners.map(
        partner => partner.id
      );
    }

    if (isNotNilOrEmpty(searchFilters.lineIds)) {
      searchParamsJson['lines'] = searchFilters.lineIds.map(line => line.uuid);
    }

    if (isNotNilOrEmpty(searchFilters.countries)) {
      searchParamsJson['countries'] = searchFilters.countries.map(
        country => country.id
      );
    }

    if (isNotNilOrEmpty(searchFilters.costCalculationName)) {
      searchParamsJson['name'] = searchFilters?.costCalculationName[0]?.name;
    }

    if (isNotNilOrEmpty(searchFilters.calculationStatus)) {
      searchParamsJson['statuses'] = searchFilters.calculationStatus.map(
        status => status.title
      );
    }

    if (
      isNotNilOrEmpty(searchFilters.planningPeriods) &&
      isNotNilOrEmpty(searchFilters.planningPeriods[0].startDate)
    ) {
      searchParamsJson['startDate'] = new Date(
        searchFilters.planningPeriods[0].startDate
      )
        .toISOString()
        .substring(0, 10);
    }

    if (
      isNotNilOrEmpty(searchFilters.planningPeriods) &&
      isNotNilOrEmpty(searchFilters.planningPeriods[0].endDate)
    ) {
      searchParamsJson['endDate'] = new Date(
        searchFilters.planningPeriods[0].endDate
      )
        .toISOString()
        .substring(0, 10);
    }
    return searchParamsJson;
  };

  const getOptions = async () => {
    const authToken = await acquireAccessToken();
    const unitsSystem = getUnitsSystemFromLocalStorage();

    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'units-system': unitsSystem,
        Authorization: authToken ? `Bearer ${authToken}` : null
      },
      body: JSON.stringify(getSearchParams())
    };
  };

  const handleStatus = result => {
    if (!result.ok) {
      throw new Error(result.statusText);
    }
    return result;
  };

  const handleBlobContent = blob => {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob], {type: 'xlsx'}));
    const link = document.createElement('a');
    link.href = url;

    const dateTime = new Date()
      .toISOString()
      .replace(/[^0-9]/g, '')
      .slice(0, -3);
    const fileNameWithDate = `${fileName}_${dateTime}.xlsx`;
    link.setAttribute('download', fileNameWithDate);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadAnalyticsData = async () => {
    const options = await getOptions();
    fetch(url, options)
      .then(handleStatus)
      .then(response => response.blob())
      .then(handleBlobContent)
      .catch(error => {
        console.error('Error downloading analytics data', error);
      });
  };

  return (
    <div>
      <Button onClick={() => downloadAnalyticsData()}>
        <Icon InlineIcon={IconDownload} aria-hidden="true" />
        <span style={{fontSize: 'smaller'}}>{name}</span>
      </Button>
    </div>
  );
};
export default React.memo(DownloadAnalyticsViewButton);
